import React from 'react';
import { Link } from 'react-router-dom';

import UserHeader from '../../../containers/UserHeader';
import ProductContractDetailCard from './ProductContractDetailCard';
import ProductHistory from './ProductHistory';
import LoggedOutFooter from '../../Shared/LoggedOutFooter';
import showPdpModal from '../../Shared/pdpShowModal';
import ReceiptUpload from './ReceiptUpload';
import ClydeModal from '../../../containers/Modal';

import { ReactComponent as LeftArrow } from '../../../images/left-arrow.svg';

interface PDPProps {
  isUser: any;
  contractTerms?: any;
  historyLog?: any;
  match: any;
  history: any;
  products: any;
  selectedProduct: any;
  newClaim: any;
  receiptUploadProcessing: boolean;
  receiptUploadError: boolean;
  merchantSettings: any;
  issueTypes: any[];
  issueTypesProcessing: boolean;
  error: string;
  transferSuccess: boolean;
  _getProductHistory: any;
  _getProduct: any;
  _getIssueTypes: any;
  _getProductTermsInfo: any;
  _getUserDetails: any;
  _setSelectedProduct: any;
  _showModal: any;
  _cancelModal: any;
  _changeTermsKey:any;
  _createAdminHandoffClaim:any;
  _getMerchantSettings:any;
  _resetProductResolutionSuccess:any;
  _uploadReceiptPicture:any;
  _setError: any;
  _clearError: any;
}

class PDP extends React.Component<PDPProps, any> {
  state: Readonly<any> = {
    canFileClaim: true,
  };

  componentDidMount() {
    this.setTitle();
    const { contractSaleId } = this.props.match.params;
    this.props._getUserDetails();
    if (this.props.products.length > 0) {
      this.props._setSelectedProduct(contractSaleId);
    } else {
      // getProductTermsInfo hooked into the call below at the action level because async
      this.props._getProduct(contractSaleId);
    }

    this.props._getProductHistory(contractSaleId);
    this.props._getMerchantSettings(contractSaleId);
  }

  componentDidUpdate(prevProps: any) {
    if (!this.props.isUser) {
      this.props.history.push('/login');
    } else {
      window.scrollTo(0, 0);
    }

    if (prevProps.selectedProduct.name !== this.props.selectedProduct.name) {
      this.setTitle();
    }

    if (this.props.error) {
      this.props._setError(this.props.error);
    }

    if (prevProps.historyLog !== this.props.historyLog) {
      const { historyLog } = this.props;
      const claimInfo = historyLog && historyLog.claimInfo ? historyLog.claimInfo : {};
      const { claimState = '', resolutionState = '' } = claimInfo;

      const activeClaimStates = [
        'inProcess',
        'underClydeReview',
        'underAdminReview',
        'needMoreInfo',
        'approved',
        'resolutionInProgress',
      ];

      let canFileClaim = true;

      if (activeClaimStates.indexOf(claimState) > -1) {
        canFileClaim = false;

        if (claimState === 'approved' && resolutionState === 'resolutionFulfilled') {
          canFileClaim = true;
        }
      }

      this.setState({ canFileClaim });
    }
  }

  componentWillUnmount() {
    this.props._resetProductResolutionSuccess();
    this.props._clearError();
  }

  setTitle = () => {
    if (this.props.selectedProduct && this.props.selectedProduct.name) {
      const title = this.props.selectedProduct.name;
      document.title = `Clyde | ${title.length > 25 ? `${title.slice(0, 25).trim()}...` : title}`;
    }
  };

  handleShowModal = (modalID: string) => {
    window.scrollTo(0, 0);
    this.props._showModal(modalID);
    document.body.classList.add('overflow-off');
  };

  render() {
    const {
      contractTerms,
      historyLog,
      selectedProduct,
      selectedProduct: {
        // isEligibleForTransfer,
        cartId,
        shopID,
      },
      transferSuccess,
      newClaim,
      receiptUploadProcessing,
      receiptUploadError,
      issueTypes,
      issueTypesProcessing,
      _cancelModal,
      _changeTermsKey,
      _getProductTermsInfo,
      _createAdminHandoffClaim,
      _showModal,
      _uploadReceiptPicture,
      _getIssueTypes,
      history,
    } = this.props;

    const { canFileClaim } = this.state;

    return (
      <>
        <main className="app-container">
          <UserHeader activeTab='products' />
          <section className="pdp-container">
            <div className='pdp-top'>
              <Link data-test="backToAllProducts" className="link-general pdp-back-link" to="/products">
                <span><LeftArrow /></span>
                <span>Back to All Products</span>
              </Link>
              <ReceiptUpload
                propsTime={ Date.now() }
                csSource={ selectedProduct.csSource }
                receiptFile={ selectedProduct.receiptFile }
                receiptUploadProcessing={ receiptUploadProcessing }
                receiptUploadError={ receiptUploadError }
                _uploadReceipt={ (uploadData:any) => _uploadReceiptPicture(selectedProduct.contractSaleId, uploadData) }
              />
            </div>
            <ProductContractDetailCard
              contractTerms={ contractTerms }
              product={ selectedProduct }
              newClaim={ newClaim }
              issueTypes={ issueTypes }
              issueTypesProcessing={ issueTypesProcessing }
              isGuest={ false }
              claimHistory={ historyLog || {}}
              _getIssueTypes={ _getIssueTypes }
              _cancelModal={ _cancelModal }
              _changeTermsKey={ _changeTermsKey }
              _getProductTermsInfo={ _getProductTermsInfo }
              _showModal={ (modalID:string) => { showPdpModal(_showModal, modalID); } }
              _createAdminHandoffClaim={ _createAdminHandoffClaim }
              history={ this.props.history }
            />
            <ProductHistory
              product={ selectedProduct }
              history={ historyLog || {} }
              hashedSaleID={ selectedProduct.hashedSaleID }
              _showModal={ (modalID:string) => { showPdpModal(_showModal, modalID); } }
              _closeModal={ _cancelModal }
            />
          </section>
        </main>
        <LoggedOutFooter />
      </>
    );
  }
}

export default PDP;
