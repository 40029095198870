import React from 'react';

import { LoadingDots } from '@joinclyde/clyde-react-components';
import UserHeader from '../../containers/UserHeader';
import ProductListItem from './ProductListItem';
import LoggedOutFooter from '../Shared/LoggedOutFooter';
import ClydeModal from '../../containers/Modal';
import ResolutionAlertModal from '../ResolutionAlert/ResolutionAlertModal';
import NoProducts from './NoProducts';

interface ProductProps {
  _getProduct: any;
  _getProducts: any;
  _markAsRead: any;
  _addToAvailableItems: any;
  _resetCheckout: any;
  _showModal: any;
  _cancelModal: any;
  _setError: any;
  _clearError: any;
  products: any[];
  loadingProducts: boolean;
  resolutionAlerts: any[];
  cart: any;
  isUser: boolean;
  match?: any;
  history?: any;
  isCheckoutSuccess?: boolean;
  error: string;
}

interface ProductState {
  product: any;
}
class ProductList extends React.Component<ProductProps> {
  state: ProductState = {
    product: {},
  };

  componentDidMount() {
    document.title = 'Clyde | Your Products';
    this.props._getProducts();
  }

  componentWillUnmount() {
    this.props._clearError();
  }

  componentDidUpdate() {
    if (!this.props.isUser) {
      this.props.history.push('/login');
    } else {
      window.scrollTo(0, 0);
      if (this.props.isCheckoutSuccess) {
        this.props._resetCheckout();
      }
    }

    if (this.props.resolutionAlerts.length) {
      this.props._showModal('resolution-alert');
    }

    if (this.props.error) {
      this.props._setError(this.props.error);
    }
  }

  handleEmptyProducts = () => {
    const { loadingProducts } = this.props;
    if (loadingProducts) {
      return (
        <section className='purchase-list-container'>
          <div className='purchase-list empty'>
            <div><h2 className='purchase-list-title'>Products</h2></div>
              <div className='purchase-list-loading-dots-container'>
                <div className='products-loading-text'>Loading products, hang on one sec...</div>
                <LoadingDots dotColor='clydeBlack' dotSize='large' />
              </div>
          </div>
        </section>
      );
    }

    return <NoProducts />;
  };

  render() {
    const {
      products, resolutionAlerts, _addToAvailableItems, _markAsRead,
    } = this.props;
    const {
      cartId,
      shopID,
    } = this.state.product;
    const currentAlert = resolutionAlerts.length > 0 && resolutionAlerts[0];

    return (
      <React.Fragment>
        <main className='app-container'>
          <UserHeader activeTab='products' />
          {products.length <= 0 ? this.handleEmptyProducts() : (
            <section className='purchase-list-container'>
                <div className='purchase-list'>
              {products.length > 0 && <div><h2 className='purchase-list-title'>Products</h2></div>}
                  {
                    products.map((product, i) => (
                      <ProductListItem
                        key={ i }
                        _addToAvailableItems={ _addToAvailableItems }
                        product={ product } />
                    ))
                  }
                </div>
            </section>
          )}
          { products.length > 0
            && <aside className='app-underlay' /> }
        </main>
        { currentAlert
          && <ClydeModal
            modalID='resolution-alert'
            customClass='clyde-modal resolution-alert-modal'
            _onClose={ () => _markAsRead(currentAlert.resolutionId)}
          >
            <ResolutionAlertModal
              resolutionInfo={ currentAlert }
              _markAsRead={ () => _markAsRead(currentAlert.resolutionId)}
            />
          </ClydeModal>
        }
        <LoggedOutFooter />
      </React.Fragment>
    );
  }
}

export default ProductList;
